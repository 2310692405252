import html2pdf from 'html2pdf.js'
// import jsPDF from 'jspdf'

import * as authApi from '../api/authApi'
import * as adminAuthApi from '@/api/adminAuthApi'
import * as usersApi from '../api/usersApi'
import * as clientsApi from '../api/clientsApi'
import * as prospectsApi from '../api/prospectsApi'
import * as customersApi from '../api/customersApi'
import * as mutationTypes from './mutationTypes'
import * as pipelinesApi from '../api/pipelinesApi'
import * as reportsApi from '../api/reportsApi'
import * as telemetryApi from '../api/telemetryApi'
import * as subscriptionsApi from '../api/subscriptionsApi'
import * as companyApi from '../api/companyApi'
import {
  ProspectStatus,
  DealType,
  CustomerPipelineStatusCode,
} from '../utils/constants'
import { dragNdropHelper, getBrowserVersion } from '../utils/helpers'

//UndoRedo
export const handelUndoRedoClick = async ({ dispatch }, stackName) => {
  let currentStack = sessionStorage.getItem(stackName)
  currentStack = JSON.parse(currentStack)

  if (!currentStack.length) return

  const actionToHandle = currentStack[0]

  switch (actionToHandle.type) {
    case 'updateClient':
      if (stackName === 'undoStack') {
        await dispatch('UndoUpdateClient', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateClient', actionToHandle.next_data)
      }
      break
    case 'createClient':
      if (stackName === 'undoStack') {
        await dispatch('deleteClients', [actionToHandle.data])
      } else if (stackName === 'redoStack') {
        let redoStack = sessionStorage.getItem('redoStack')
        redoStack = JSON.parse(redoStack)
        redoStack.shift()
        sessionStorage.setItem('redoStack', JSON.stringify(redoStack))
      }
      break
    case 'moveClientsToProspects':
      if (stackName === 'undoStack') {
        await dispatch('UndoMoveProspectToClient', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveClientsToProspects', actionToHandle.data)
      }
      break
    case 'archiveClients':
      if (stackName === 'undoStack') {
        await dispatch('UndorestoreClients', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('archiveClients', actionToHandle.data)
      }
      break
    case 'AddDeals':
      if (stackName === 'undoStack') {
        await dispatch('UndoremoveClientDeals', actionToHandle.deals_ids)
      } else if (stackName === 'redoStack') {
        await dispatch('addDeals', actionToHandle.subject_ids)
      }
      break
    case 'updateProspect':
      if (stackName === 'undoStack') {
        await dispatch('UndoUpdateProspect', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateProspect', actionToHandle.next_data)
      }
      break
    case 'updateClientDealValue':
      if (stackName === 'undoStack') {
        await dispatch('UndoupdateClientDealValue', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateClientDealValue', actionToHandle.next_data)
      }
      break

    case 'moveProspectsToClients':
      if (stackName === 'undoStack') {
        await dispatch('UndoMoveClientsToProspects', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveProspectsToClients', actionToHandle.data)
      }
      break
    case 'archiveProspects':
      if (stackName === 'undoStack') {
        await dispatch('UndorestoreProspects', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('archiveProspects', actionToHandle.data)
      }
      break
    case 'createProspect':
      if (stackName === 'undoStack') {
        await dispatch('deleteProspects', [actionToHandle.data])
      } else if (stackName === 'redoStack') {
        let redoStack = sessionStorage.getItem('redoStack')
        redoStack = JSON.parse(redoStack)
        redoStack.shift()
        sessionStorage.setItem('redoStack', JSON.stringify(redoStack))
      }
      break
    case 'removeClientDeals':
      if (stackName === 'undoStack') {
        await dispatch('UndoAddDeals', actionToHandle.subject_ids)
      } else if (stackName === 'redoStack') {
        await dispatch('removeClientDeals', actionToHandle.deals_ids)
      }
      break
    case 'openClientDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndocloseClientDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('openClientDeal', actionToHandle.data)
      }
      break
    case 'closeClientDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndoopenClientDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('closeClientDeal', actionToHandle.data)
      }
      break
    case 'createClientAndDeal':
      if (stackName === 'undoStack') {
        if (actionToHandle.data) {
          await dispatch('UndoremoveClientDeals', [actionToHandle.data])
        }

        await dispatch('deleteClients', [actionToHandle.id_subject])
      } else if (stackName === 'redoStack') {
        let redoStack = sessionStorage.getItem('redoStack')
        redoStack = JSON.parse(redoStack)
        redoStack.shift()
        sessionStorage.setItem('redoStack', JSON.stringify(redoStack))
      }
      break
    case 'moveClientsPipelineToProspects':
      if (stackName === 'undoStack') {
        let { dealIds, subjectIds } = actionToHandle.data
        await dispatch('UndoMoveProspectToClient', subjectIds)
        // await dispatch('UndoAddDeals',subjectIds)
      } else if (stackName === 'redoStack') {
        await dispatch('moveClientsPipelineToProspects', actionToHandle.data)
      }
      break
    case 'createProspectAndDeal':
      if (stackName === 'undoStack') {
        if (actionToHandle.statusDeals === ProspectStatus.Target) {
          await dispatch('UndoremoveTargetDeals', [actionToHandle.data])
          await dispatch('deleteProspects', [actionToHandle.id_subject])
        } else if (actionToHandle.statusDeals === ProspectStatus.Hot) {
          await dispatch('UndoremoveTargetDeals', [actionToHandle.data])
          await dispatch('deleteProspects', [actionToHandle.id_subject])
        } else if (
          actionToHandle.statusDeals === ProspectStatus['In Progress']
        ) {
          await dispatch('UndoremoveInProgressDeals', [actionToHandle.data])
          await dispatch('deleteProspects', [actionToHandle.id_subject])
        } else if (
          actionToHandle.statusDeals === ProspectStatus['Not in Pipeline']
        ) {
          break
        }
      } else if (stackName === 'redoStack') {
        let redoStack = sessionStorage.getItem('redoStack')
        redoStack = JSON.parse(redoStack)
        redoStack.shift()
        sessionStorage.setItem('redoStack', JSON.stringify(redoStack))
        break
      }
    case 'removeTargetDeals':
      if (stackName === 'undoStack') {
        await dispatch('UndoAddDeals', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('removeTargetDeals', actionToHandle.data)
      }
      break
    case 'openTargetDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndocloseTargetDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('openTargetDeal', actionToHandle.data)
      }
      break
    case 'closeTargetDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndoopenTargetDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('closeTargetDeal', actionToHandle.data)
      }
      break
    case 'openHotDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndocloseHotDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('openHotDeal', actionToHandle.data)
      }
      break
    case 'closeHotDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndoopenHotDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('closeHotDeal', actionToHandle.data)
      }
      break
    case 'openInProgressDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndocloseInProgressDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('openInProgressDeal', actionToHandle.data)
      }
      break
    case 'closeInProgressDeal':
      if (stackName === 'undoStack') {
        await dispatch('UndoopenInProgressDeal', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('closeInProgressDeal', actionToHandle.data)
      }
      break
    case 'moveTargetDealToHot':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveHotDealToTarget', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await ('moveTargetDealToHot', actionToHandle.data)
      }
      break
    case 'moveTargetDealToInProgress':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveInProgressDealToTarget', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await ('moveTargetDealToInProgress', actionToHandle.data)
      }
      break
    case 'moveHotDealToInProgress':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveInProgressDealToHot', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveHotDealToInProgress', actionToHandle.data)
      }
      break
    case 'moveHotDealToTarget':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveTargetDealToHot', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveHotDealToTarget', actionToHandle.data)
      }
      break
    case 'moveInProgressDealToHot':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveHotDealToInProgress', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveInProgressDealToHot', actionToHandle.data)
      }
      break
    case 'moveInProgressDealToTarget':
      if (stackName === 'undoStack') {
        await dispatch('UndomoveTargetDealToInProgress', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('moveInProgressDealToHot', actionToHandle.data)
      }
      break
    case 'restoreClients':
      if (stackName === 'undoStack') {
        await dispatch('UndoarchiveClients', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('restoreClients', actionToHandle.data)
      }
      break
    case 'restoreProspects':
      if (stackName === 'undoStack') {
        await dispatch('UndoarchiveProspects', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('restoreProspects', actionToHandle.data)
      }
      break
    case 'updateHotDealValue':
      if (stackName === 'undoStack') {
        await dispatch('UndoupdateHotDealValue', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateHotDealValue', actionToHandle.next_data)
      }
      break
    case 'updateTargetDealValue':
      if (stackName === 'undoStack') {
        await dispatch('UndoupdateTargetDealValue', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateTargetDealValue', actionToHandle.next_data)
      }
      break
    case 'updateInProgressDealValue':
      if (stackName === 'undoStack') {
        await dispatch('UndoupdateInProgressDealValue', actionToHandle.data)
      } else if (stackName === 'redoStack') {
        await dispatch('updateInProgressDealValue', actionToHandle.next_data)
      }
      break
    case 'updateClientsOrder':
      if (stackName === 'undoStack') {
        const undoPayload = {
          ...actionToHandle.data,
          oldLocalIndex: actionToHandle.data.newLocalIndex,
          newLocalIndex: actionToHandle.data.oldLocalIndex,
        }
        await dispatch('UndoupdateClientsOrder', undoPayload)
      } else if (stackName === 'redoStack') {
        await dispatch('updateClientsOrder', actionToHandle.data)
      }
      break
    case 'updateProspectsOrder':
      if (stackName === 'undoStack') {
        const undoPayload = {
          ...actionToHandle.data,
          oldLocalIndex: actionToHandle.data.newLocalIndex,
          newLocalIndex: actionToHandle.data.oldLocalIndex,
        }
        await dispatch('UndoupdateProspectsOrder', undoPayload)
      } else if (stackName === 'redoStack') {
        await dispatch('updateProspectsOrder', actionToHandle.data)
      }
      break
    case 'updateDealsOrder':
      if (stackName === 'undoStack') {
        const undoPayload = {
          ...actionToHandle.data,
          oldLocalIndex: actionToHandle.data.newLocalIndex,
          newLocalIndex: actionToHandle.data.oldLocalIndex,
        }
        await dispatch('UndoupdateDealsOrder', undoPayload)
      } else if (stackName === 'redoStack') {
        await dispatch('updateDealsOrder', actionToHandle.data)
      }
      break
    default:
      break
  }

  currentStack.shift()
  sessionStorage.setItem(stackName, JSON.stringify(currentStack))

  if (stackName === 'undoStack') {
    let redoStack = sessionStorage.getItem('redoStack')
    redoStack = JSON.parse(redoStack)
    if (redoStack.length >= 20) {
      redoStack.pop()
      redoStack.unshift(actionToHandle)
    }
    redoStack.unshift(actionToHandle)
    sessionStorage.setItem('redoStack', JSON.stringify(redoStack))
  }
}

export const UndoupdateDealsOrder = async ({ commit, state }, payload) => {
  const { dealsType } = payload
  let elementsList = []
  if (dealsType == DealType.ClientDeal) {
    elementsList = state.clientDeals.clientDealsList
  }
  if (dealsType == DealType.HotProspectDeal) {
    elementsList = state.hotDeals.hotDealsList
  }
  if (dealsType == DealType.InProgressProspectDeal) {
    elementsList = state.inProgressDeals.inProgressDealsList
  }
  if (dealsType == DealType.TargetProspectDeal) {
    elementsList = state.targetDeals.targetDealsList
  }
  payload['elementsList'] = elementsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  if (element.deal_type === DealType.ClientDeal) {
    commit(mutationTypes.REPLACE_CLIENT_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.HotProspectDeal) {
    commit(mutationTypes.SET_HOT_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.InProgressProspectDeal) {
    commit(mutationTypes.SET_IN_PROGRESS_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.TargetProspectDeal) {
    commit(mutationTypes.SET_TARGET_DEALS_LIST, updatedList)
  }

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
      item_status: element.deal_type,
    },
  }

  await pipelinesApi.updateDealsDefaultOrder(reorderedElement)
}

export const UndoupdateProspectsOrder = async ({ commit, state }, payload) => {
  const {
    prospects: { prospectsList },
  } = state
  payload['elementsList'] = prospectsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  commit(mutationTypes.REPLACE_PROSPECTS_LIST, updatedList)

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
    },
  }

  await prospectsApi.updateProspectsDefaultOrder(reorderedElement)
}
export const UndoupdateClientsOrder = async ({ commit, state }, payload) => {
  const {
    clients: { clientsList },
  } = state
  payload['elementsList'] = clientsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  commit(mutationTypes.REPLACE_CLIENTS_LIST, updatedList)

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
    },
  }
  await clientsApi.updateClientsDefaultOrder(reorderedElement)
}
export const UndoupdateInProgressDealValue = async (
  { state, commit },
  payload
) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, payload)
}

export const UndoupdateTargetDealValue = async ({ commit }, payload) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_TARGET_DEAL, payload)
}

export const UndoupdateHotDealValue = async ({ commit }, payload) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_HOT_DEAL, payload)
}

export const UndoupdateClientDealValue = async ({ commit }, dealUpdate) => {
  const pipelineValues = await pipelinesApi.updateDealValue(dealUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)
}

export const UndoarchiveProspects = async ({ state, commit }, payload) => {
  const {
    prospects: { prospectsCount },
  } = state
  const pipelineValues = await prospectsApi.archiveProspects({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount - payload.length)
}

export const UndoarchiveClients = async ({ state, commit }, payload) => {
  const {
    clients: { clientsCount },
  } = state
  const pipelineValues = await clientsApi.archiveClients({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount - payload.length)
}

export const UndorestoreProspects = async ({ state, commit }, payload) => {
  await prospectsApi.restoreProspects({ ids: payload })

  const {
    archive: { archivedProspectsCount },
  } = state
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT,
    archivedProspectsCount - payload.length
  )
}

export const UndorestoreClients = async ({ state, commit }, payload) => {
  await clientsApi.restoreClients({ ids: payload })

  const {
    archive: { archivedClientsCount },
  } = state
  commit(mutationTypes.REMOVE_CLIENTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT,
    archivedClientsCount - payload.length
  )
}

//UndoRedo ProspectPipeline actions

export const UndomoveHotDealToInProgress = async (
  { state, commit },
  payload
) => {
  const {
    hotDeals: { hotDealsCount, hotDealsList },
    inProgressDeals: { inProgressDealsCount },
  } = state

  const deal = hotDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.InProgressProspectDeal

  commit(mutationTypes.REMOVE_HOT_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - 1)
  commit(mutationTypes.ADD_IN_PROGRESS_DEAL, deal)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndomoveTargetDealToInProgress = async (
  { state, commit },
  payload
) => {
  const {
    targetDeals: { targetDealsCount, targetDealsList },
    inProgressDeals: { inProgressDealsCount },
  } = state

  const deal = targetDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.InProgressProspectDeal

  commit(mutationTypes.REMOVE_TARGET_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount - 1)
  commit(mutationTypes.ADD_IN_PROGRESS_DEAL, deal)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndomoveTargetDealToHot = async ({ state, commit }, payload) => {
  const {
    targetDeals: { targetDealsCount, targetDealsList },
    hotDeals: { hotDealsCount },
  } = state

  const deal = targetDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.HotProspectDeal

  commit(mutationTypes.REMOVE_TARGET_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount - 1)
  commit(mutationTypes.ADD_HOT_DEAL, deal)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndomoveInProgressDealToHot = async (
  { state, commit },
  payload
) => {
  const {
    inProgressDeals: { inProgressDealsCount, inProgressDealsList },
    hotDeals: { hotDealsCount },
  } = state

  const deal = inProgressDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.HotProspectDeal

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount - 1)
  commit(mutationTypes.ADD_HOT_DEAL, deal)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndomoveHotDealToTarget = async ({ state, commit }, payload) => {
  const {
    hotDeals: { hotDealsCount, hotDealsList },
    targetDeals: { targetDealsCount },
  } = state

  const deal = hotDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.TargetProspectDeal

  commit(mutationTypes.REMOVE_HOT_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - 1)
  commit(mutationTypes.ADD_TARGET_DEAL, deal)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndomoveInProgressDealToTarget = async (
  { state, commit },
  payload
) => {
  const {
    inProgressDeals: { inProgressDealsCount, inProgressDealsList },
    targetDeals: { targetDealsCount },
  } = state

  const deal = inProgressDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.TargetProspectDeal

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount - 1)
  commit(mutationTypes.ADD_TARGET_DEAL, deal)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoopenInProgressDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndocloseInProgressDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoopenTargetDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_TARGET_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndocloseTargetDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_TARGET_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoopenHotDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_HOT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndocloseHotDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_HOT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoremoveInProgressDeals = async ({ state, commit }, payload) => {
  const {
    inProgressDeals: { inProgressDealsCount },
  } = state

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, payload)
  commit(
    mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT,
    inProgressDealsCount - payload.length
  )

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoremoveTargetDeals = async ({ state, commit }, payload) => {
  const {
    targetDeals: { targetDealsCount },
  } = state

  commit(mutationTypes.REMOVE_TARGET_DEALS, payload)
  commit(
    mutationTypes.UPDATE_TARGET_DEALS_COUNT,
    targetDealsCount - payload.length
  )

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoremoveHotDeals = async ({ state, commit }, payload) => {
  const {
    hotDeals: { hotDealsCount },
  } = state

  commit(mutationTypes.REMOVE_HOT_DEALS, payload)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - payload.length)

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoremoveClientDeals = async ({ state, commit }, payload) => {
  const {
    clientDeals: { clientDealsList, clientDealsCount },
  } = state
  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  if (clientDealsList.length) {
    commit(mutationTypes.REMOVE_CLIENT_DEALS, payload)
    commit(
      mutationTypes.UPDATE_CLIENT_DEALS_COUNT,
      clientDealsCount - payload.length
    )
  }
}

export const UndoopenClientDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndocloseClientDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoAddDeals = async ({}, payload) => {
  await pipelinesApi.addDeals(payload)
}

export const UndoUpdateProspect = async ({ commit }, updatedProspect) => {
  const prospectUpdate = {
    name: updatedProspect.name,
    company: updatedProspect.company,
    status: updatedProspect.status,
  }
  const payload = {
    prospectId: updatedProspect.id,
    update: { prospect_update: prospectUpdate },
  }

  commit(mutationTypes.UPDATE_PROSPECT, updatedProspect)

  const pipelineValues = await prospectsApi.updateProspect(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const UndoUpdateClient = async ({ state, commit }, updatedClient) => {
  const clientUpdate = {
    name: updatedClient.name,
    company: updatedClient.company,
  }
  const payload = {
    clientId: updatedClient.id,
    update: { client_update: clientUpdate },
  }

  await clientsApi.updateClient(payload)

  commit(mutationTypes.UPDATE_CLIENT, updatedClient)
}

export const UndoMoveProspectToClient = async ({ state, commit }, payload) => {
  const {
    prospects: { prospectsCount },
  } = state
  const pipelineValues = await prospectsApi.moveProspectsToClients({
    ids: payload,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount - payload.length)
}

export const UndoMoveClientsToProspects = async (
  { state, commit },
  payload
) => {
  const {
    clients: { clientsCount },
  } = state
  const pipelineValues = await clientsApi.moveClientsToProspects({
    ids: payload,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount - payload.length)
}

// Telemetry and feedbacks
export const sendFeedback = async ({ state }, feedbackText) => {
  const { currentUser } = state
  const browserVersion = getBrowserVersion()
  const payload = {
    feedback_data: {
      user_id: currentUser.id,
      user_email: currentUser.email,
      feedback_text: feedbackText,
      browser_version: browserVersion,
    },
  }

  await telemetryApi.sendFeedback(payload)
}
// Admin Auth
export const AdminsignIn = async ({ commit }, payload) => {
  const { user, tokens } = await adminAuthApi.adminSignIn(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []
  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)
}

// Auth
export const signUp = async ({ commit }, payload) => {
  const { user, tokens } = await authApi.signUp(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []

  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)
}

export const signIn = async ({ commit }, payload) => {
  const { user, tokens, company } = await authApi.signIn(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []

  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)

  if (company) {
    commit(mutationTypes.SET_COMPANY, company)
  }
}

export const b2bSignUp = async ({ commit }, payload) => {
  const { user, tokens, company } = await authApi.b2bSignUp(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []

  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)
  commit(mutationTypes.SET_COMPANY, company)
}

export const b2bSignIn = async ({ commit }, payload) => {
  const { user, tokens, company } = await authApi.b2bSignIn(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []

  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)

  if (company) {
    commit(mutationTypes.SET_COMPANY, company)
  }
}

export const b2bMemberActivation = async ({ commit }, payload) => {
  const { user, tokens } = await authApi.b2bMemberActivation(payload)
  const { access_token, refresh_token } = tokens
  const undoStack = []
  const redoStack = []

  sessionStorage.setItem('accessToken', access_token)
  sessionStorage.setItem('refreshToken', refresh_token)
  sessionStorage.setItem('currentUser', JSON.stringify(user))
  sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
  sessionStorage.setItem('redoStack', JSON.stringify(redoStack))

  commit(mutationTypes.SET_CURRENT_USER, user)
}

export const signOut = async ({ commit }) => {
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('refreshToken')
  sessionStorage.removeItem('currentUser')
  sessionStorage.removeItem('undoStack')

  commit(mutationTypes.CLEAR_CLIENTS)
  commit(mutationTypes.CLEAR_PROSPECTS)
  commit(mutationTypes.CLEAR_COMPANY)

  setTimeout(() => commit(mutationTypes.CLEAR_CURRENT_USER), 300)
}

export const clearCurrentUser = async ({ commit }) => {
  commit(mutationTypes.CLEAR_CURRENT_USER)
}

export const requestPasswordResetLink = async ({}, payload) => {
  await authApi.requestPasswordResetLink(payload)
}

export const resetPassword = async ({}, payload) => {
  await authApi.resetPassword(payload)
}

// Users
export const updateUserLocally = async ({ commit }, payload) => {
  let currentUser = sessionStorage.getItem('currentUser')
  currentUser = JSON.parse(currentUser)

  if (payload.subscription) {
    currentUser = {
      ...currentUser,
      subscription: { ...currentUser.subscription, ...payload.subscription },
    }
  } else {
    currentUser = { ...currentUser, ...payload }
  }

  sessionStorage.setItem('currentUser', JSON.stringify(currentUser))
  commit(mutationTypes.UPDATE_CURRENT_USER, currentUser)
}

export const updateUser = async ({ dispatch }, updated_user) => {
  await usersApi.updateUser(updated_user)

  await dispatch('updateUserLocally', updated_user)
}

export const changeUserPassword = async ({}, passwords) => {
  await usersApi.changeUserPassword(passwords)
}

export const getNextMembersPage = async ({ commit }, payload) => {
  const { members_count: membersCount, next_page: nextPage } =
    await usersApi.getMembers(payload)

  commit(mutationTypes.UPDATE_MEMBERS_COUNT, membersCount)
  commit(mutationTypes.UPDATE_MEMBERS_LIST, nextPage)
}

export const getNextUsersPage = async ({ commit }, payload) => {
  const nextPage = await usersApi.getAttachedUsers(payload)

  commit(mutationTypes.UPDATE_ATTACHED_USERS, nextPage)
}

export const updateMember = async ({ commit }, payload) => {
  await usersApi.updateMember(payload)

  commit(mutationTypes.UPDATE_MEMBER, payload)
}

export const clearMembers = async ({ commit }) => {
  commit(mutationTypes.CLEAR_ADMIN_MEMBERS)
  commit(mutationTypes.UPDATE_MEMBERS_COUNT, 0)
}

export const clearAdminBillings = async ({ state, commit }, payload) => {
  commit(mutationTypes.CLEAR_ADMIN_BILLINGS)
  commit(mutationTypes.UPDATE_BILLINGS_COUNT, 0)
}

export const InviteUser = async ({}, member_email) => {
  await usersApi.inviteUser(member_email)
}

export const createClientAccount = async ({ state, commit }, payload) => {
  const {
    users: { membersCount },
  } = state
  const { new_member: newMember } = await usersApi.createMember(payload)

  commit(mutationTypes.ADD_MEMBER, newMember)
  commit(mutationTypes.UPDATE_MEMBERS_COUNT, membersCount + 1)
}

export const importMembers = async ({ commit }, payload) => {
  const requestData = new FormData()
  requestData.append('upload_file', payload)

  const message = await usersApi.importMembers(requestData)

  return message
}

export const deleteUsers = async ({ state, commit }, payload) => {
  await usersApi.deleteUsers({ ids: payload })

  const {
    users: { membersCount },
  } = state
  commit(mutationTypes.REMOVE_USERS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_MEMBERS_COUNT, membersCount - payload.length)
}

export const assignSalesManagerRole = async ({ state, commit }, payload) => {
  await usersApi.assignSalesRole({ id: payload })

  commit(mutationTypes.UPDATE_MEMBER, {
    member_id: payload,
    member_update: { role: 8 },
  })
}

export const unassignSalesManagerRole = async ({ state, commit }, payload) => {
  await usersApi.unassignSalesRole(payload)

  commit(mutationTypes.UPDATE_MEMBER, {
    member_id: payload,
    member_update: { role: 1 },
  })
}

export const getAttachedUsersList = async ({ state, commit }, payload) => {
  const { total_pipeline_value, members } =
    await usersApi.getAttachedMembersList(payload)

  commit(mutationTypes.UPDATE_ATTACHED_USERS, { total_pipeline_value, members })

  return members
}

export const getUsersToAttachList = async ({ state, commit }, payload) => {
  const users = await usersApi.getUsersToAttachList(payload)

  const filteredUsers = users.next_page.map((user) => {
    const newUser = user
    newUser.isAttached = state.users.attachedUsernamesList.includes(newUser.id)
    return newUser
  })

  commit(mutationTypes.UPDATE_USERS_TO_ATTACH_LIST, filteredUsers)

  return users
}

export const unassignUserFromManager = async ({ state, commit }, payload) => {
  const users = await usersApi.unassignUserFromManager(payload)

  commit(mutationTypes.UPDATE_ATTACHED_USERS, {
    members: state.users.attachedUsersList.filter(
      (user) => user.id !== payload.memberId
    ),
  })

  return users
}

export const assignUserToManager = async ({ state, commit }, payload) => {
  await usersApi.attachUser(payload)
}

export const searchUsers = async ({ commit }, payload) => {
  const { members: nextPage, members_count: usersCount } =
    await clientsApi.getClients(payload)
  commit(mutationTypes.REPLACE_MEMBERS_LIST, nextPage)
  commit(mutationTypes.UPDATE_MEMBERS_COUNT, usersCount)
}

export const getB2BMembers = async ({ commit }) => {
  const { company_members: companyMembers } = await usersApi.getB2BMembers()
  commit(mutationTypes.SET_B2B_MEMBERS_LIST, companyMembers)
}

export const deleteB2BMember = async (
  { commit, dispatch, state },
  member_id
) => {
  commit(mutationTypes.DELETE_B2B_MEMBER, member_id)
  await subscriptionsApi.deleteB2BMember(member_id)

  const {
    currentUser: {
      subscription: { quantity },
    },
  } = state
  const newSubscriptionQuantity = quantity - 1

  const userUpdate = { subscription: { quantity: newSubscriptionQuantity } }
  await dispatch('updateUserLocally', userUpdate)
}

// Company
export const getCompany = async ({ commit }) => {
  const { company } = await companyApi.getCompany()
  commit(mutationTypes.SET_COMPANY, company)
}

export const updateCompany = async ({ commit }, payload) => {
  commit(mutationTypes.UPDATE_COMPANY, payload)
  await companyApi.updateCompany({ company_update: payload })
}

// Clients
export const getNextClientsPage = async ({ commit }, payload) => {
  const { clients_count: clientsCount, next_page: nextPage } =
    await clientsApi.getClients(payload)
  commit(mutationTypes.UPDATE_CLIENTS_LIST, nextPage)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount)
}

export const updateClientsOrder = async ({ commit, state }, payload) => {
  const {
    clients: { clientsList },
  } = state
  payload['elementsList'] = clientsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  commit(mutationTypes.REPLACE_CLIENTS_LIST, updatedList)

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
    },
  }

  await clientsApi.updateClientsDefaultOrder(reorderedElement)
}

export const searchClients = async ({ commit }, payload) => {
  const { clients_count: clientsCount, next_page: nextPage } =
    await clientsApi.getClients(payload)
  commit(mutationTypes.REPLACE_CLIENTS_LIST, nextPage)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount)
}

export const searchArchiveClients = async ({ commit }, payload) => {
  const { archived_clients_count: archivedClientsCount, next_page: nextPage } =
    await clientsApi.searchArchiveClients(payload)

  commit(mutationTypes.REPLACE_CLIENTS_ARCHIVE_LIST, nextPage)
  commit(mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT, archivedClientsCount)
}

export const getClientsArchivePage = async ({ state, commit }, payload) => {
  const { lastId } = payload
  const {
    archive: { archivedClientsList },
  } = state
  const indexOfLastId = archivedClientsList.findIndex(
    (client) => client.id === lastId
  )

  if (indexOfLastId + 1 === archivedClientsList.length) {
    const {
      next_page: nextPage,
      archived_clients_count: archivedClientsCount,
    } = await clientsApi.getClientsArchivePage(payload)

    commit(mutationTypes.UPDATE_CLIENTS_ARCHIVE_LIST, nextPage)
    commit(mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT, archivedClientsCount)
  }
}

export const createClient = async ({ commit }, payload) => {
  const { client, clients_count: clientsCount } = await clientsApi.createClient(
    payload
  )
  commit(mutationTypes.ADD_CLIENT, client)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount)
}

export const updateClient = async ({ commit }, updatedClient) => {
  const clientUpdate = {
    name: updatedClient.name,
    company: updatedClient.company,
  }
  const payload = {
    clientId: updatedClient.id,
    update: { client_update: clientUpdate },
  }

  await clientsApi.updateClient(payload)

  commit(mutationTypes.UPDATE_CLIENT, updatedClient)
}

export const moveClientsToProspects = async ({ state, commit }, payload) => {
  const {
    clients: { clientsCount },
  } = state
  const pipelineValues = await clientsApi.moveClientsToProspects({
    ids: payload,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount - payload.length)
}

export const moveSingleClientToProspects = async (
  { state, commit },
  payload
) => {
  const { isClientDealExist } = payload
  const {
    clients: { clientsCount },
    clientDeals: { clientDealsList, clientDealsCount },
  } = state

  delete payload.isClientDealExist

  const pipelineValues = await clientsApi.moveSingleClientToPropsects(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  if (!isClientDealExist) {
    commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, [payload.client_id])
    commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount - 1)

    return
  }

  const deal = clientDealsList.find((i) => i.subject === payload.client_id)

  commit(mutationTypes.REMOVE_CLIENT_DEALS, [deal.id])
  commit(mutationTypes.UPDATE_CLIENT_DEALS_COUNT, clientDealsCount - 1)
}

export const archiveClients = async ({ state, commit }, payload) => {
  const {
    clients: { clientsCount },
  } = state
  const pipelineValues = await clientsApi.archiveClients({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount - payload.length)
}

export const restoreClients = async ({ state, commit }, payload) => {
  await clientsApi.restoreClients({ ids: payload })

  const {
    archive: { archivedClientsCount },
  } = state
  commit(mutationTypes.REMOVE_CLIENTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT,
    archivedClientsCount - payload.length
  )
}

export const deleteClients = async ({ state, commit }, payload) => {
  const {
    archive: { archivedClientsCount },
  } = state
  const pipelineValues = await clientsApi.deleteClients({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT,
    archivedClientsCount - payload.length
  )
}

export const clearClients = async ({ commit }) => {
  commit(mutationTypes.CLEAR_CLIENTS)
  commit(mutationTypes.UPDATE_CLIENTS_COUNT, 0)
}
export const clearArchiveClients = async ({ commit }) => {
  commit(mutationTypes.CLEAR_ARCHIVE_CLIENTS)
  commit(mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT, 0)
}
export const clearArchiveProspects = async ({ commit }) => {
  commit(mutationTypes.CLEAR_ARCHIVE_PROSPECTS)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, 0)
}

// Prospects
export const getNextProspectsPage = async ({ commit }, payload) => {
  const { prospects_count: newProspectsCount, next_page: nextPage } =
    await prospectsApi.getProspects(payload)
  commit(mutationTypes.UPDATE_PROSPECTS_LIST, nextPage)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, newProspectsCount)
}

export const updateProspectsOrder = async ({ commit, state }, payload) => {
  const {
    prospects: { prospectsList },
  } = state
  payload['elementsList'] = prospectsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  commit(mutationTypes.REPLACE_PROSPECTS_LIST, updatedList)

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
    },
  }

  await prospectsApi.updateProspectsDefaultOrder(reorderedElement)
}

export const getProspectsArchivePage = async ({ state, commit }, payload) => {
  const { lastId } = payload
  const {
    archive: { archivedProspectsList },
  } = state
  const indexOfLastId = archivedProspectsList.findIndex(
    (prospect) => prospect.id === lastId
  )

  if (indexOfLastId + 1 === archivedProspectsList.length) {
    const {
      next_page: nextPage,
      archived_prospects_count: archivedProspectsCount,
    } = await prospectsApi.getProspectsArchivePage(payload)

    commit(mutationTypes.UPDATE_PROSPECTS_ARCHIVE_LIST, nextPage)
    commit(mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT, archivedProspectsCount)
  }
}

export const createProspect = async ({ commit }, payload) => {
  const { prospect, prospects_count: prospectsCount } =
    await prospectsApi.createProspect(payload)
  commit(mutationTypes.ADD_PROSPECT, prospect)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount)
}

export const updateProspect = async ({ commit }, updatedProspect) => {
  const prospectUpdate = {
    name: updatedProspect.name,
    company: updatedProspect.company,
    status: updatedProspect.status,
  }
  const payload = {
    prospectId: updatedProspect.id,
    update: { prospect_update: prospectUpdate },
  }

  commit(mutationTypes.UPDATE_PROSPECT, updatedProspect)

  const pipelineValues = await prospectsApi.updateProspect(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveProspectsToClients = async ({ state, commit }, payload) => {
  const {
    prospects: { prospectsCount },
  } = state
  const pipelineValues = await prospectsApi.moveProspectsToClients({
    ids: payload,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount - payload.length)
}

export const movePipelineProspectsToClients = async (
  { state, commit },
  payload
) => {
  const pipelineValues = await prospectsApi.moveProspectsToClients({
    ids: payload,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const archiveProspects = async ({ state, commit }, payload) => {
  const {
    prospects: { prospectsCount },
  } = state
  const pipelineValues = await prospectsApi.archiveProspects({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_LIST, payload)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount - payload.length)
}

export const restoreProspects = async ({ state, commit }, payload) => {
  await prospectsApi.restoreProspects({ ids: payload })

  const {
    archive: { archivedProspectsCount },
  } = state
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT,
    archivedProspectsCount - payload.length
  )
}

export const searchProspect = async ({ commit }, payload) => {
  const { prospects_count: prospectsCount, next_page: nextPage } =
    await prospectsApi.getProspects(payload)
  commit(mutationTypes.REPLACE_PROSPECTS_LIST, nextPage)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount)
}

export const searchArchiveProspect = async ({ commit }, payload) => {
  const {
    archived_prospects_count: archivedProspectCount,
    next_page: nextPage,
  } = await prospectsApi.searchArchiveProspect(payload)
  commit(mutationTypes.REPLACE_PROSPECTS_ARCHIVE_LIST, nextPage)
  commit(mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT, archivedProspectCount)
}

export const deleteProspects = async ({ state, commit }, payload) => {
  const {
    archive: { archivedProspectsCount },
  } = state
  const piplineValues = await prospectsApi.deleteProspects({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, piplineValues)
  commit(mutationTypes.REMOVE_PROSPECTS_FROM_ARCHIVE_LIST, payload)
  commit(
    mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT,
    archivedProspectsCount - payload.length
  )
}

export const clearProspects = async ({ commit }) => {
  commit(mutationTypes.CLEAR_PROSPECTS)
  commit(mutationTypes.UPDATE_PROSPECTS_COUNT, 0)
}

// General customers actions
export const importCustomers = async ({ commit }, payload) => {
  const requestData = new FormData()
  requestData.append('upload_file', payload)

  const { data, message } = await customersApi.importCustomers(requestData)

  const pipelineValues = {
    value: data.pipeline_value,
    projected_value: data.pipeline_projected_value,
  }

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  return message
}

// General pipeline actions
export const getPipelinesList = async ({ commit }, lastId) => {
  const { next_page: nextPage, pipelines_count: pipelinesCount } =
    await pipelinesApi.getPipelinesList(lastId)
  commit(mutationTypes.SET_PIPELINE_LIST, nextPage)
  commit(mutationTypes.UPDATE_PIPELINES_COUNT, pipelinesCount)
}

export const updatePipelinesList = async ({ commit }, lastId) => {
  const { next_page: nextPage } = await pipelinesApi.getPipelinesList(lastId)
  commit(mutationTypes.UPDATE_PIPELINES_LIST, nextPage)
}

export const getActivePipeline = async ({ commit }) => {
  const { active_pipeline: activePipeline } =
    await pipelinesApi.getActivePipeline()
  commit(mutationTypes.SET_ACTIVE_PIPELINE, activePipeline)
}

export const updatePipelineGoals = async ({ commit }, payload) => {
  await pipelinesApi.updatePipelineGoals({ goals_update: payload })
  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, payload)
}

export const triggerPlaginOnDealsCreation = async ({}, dealsData) => {}

export const addDeals = async ({ dispatch }, payload) => {
  const { deals_in_response } = await pipelinesApi.addDeals(payload)
  const dealsData = {
    deal_ids: [],
    subject_ids: [],
  }

  deals_in_response.forEach((i) => {
    dealsData.deal_ids.push(i.id)
    dealsData.subject_ids.push(i.subject)
  })

  dispatch('triggerPlaginOnDealsCreation', dealsData)
}

export const updateDealsOrder = async ({ commit, state }, payload) => {
  const { dealsType } = payload
  let elementsList = []
  if (dealsType == DealType.ClientDeal) {
    elementsList = state.clientDeals.clientDealsList
  }
  if (dealsType == DealType.HotProspectDeal) {
    elementsList = state.hotDeals.hotDealsList
  }
  if (dealsType == DealType.InProgressProspectDeal) {
    elementsList = state.inProgressDeals.inProgressDealsList
  }
  if (dealsType == DealType.TargetProspectDeal) {
    elementsList = state.targetDeals.targetDealsList
  }
  payload['elementsList'] = elementsList
  const { updatedList, element, oldElementOrder, orderDelta } =
    dragNdropHelper(payload)

  if (element.deal_type === DealType.ClientDeal) {
    commit(mutationTypes.REPLACE_CLIENT_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.HotProspectDeal) {
    commit(mutationTypes.SET_HOT_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.InProgressProspectDeal) {
    commit(mutationTypes.SET_IN_PROGRESS_DEALS_LIST, updatedList)
  } else if (element.deal_type === DealType.TargetProspectDeal) {
    commit(mutationTypes.SET_TARGET_DEALS_LIST, updatedList)
  }

  const reorderedElement = {
    reordered_item: {
      id: element.id,
      old_order: oldElementOrder,
      new_order: element.default_order,
      order_delta: orderDelta,
      item_status: element.deal_type,
    },
  }

  await pipelinesApi.updateDealsDefaultOrder(reorderedElement)
}

export const updateDealValue = async ({ commit }, payload) => {
  const { deal: dealInResponse } = await pipelinesApi.getDealBySubject(
    payload.subjectId
  )

  const dealPayload = {
    id: dealInResponse.id,
    value: payload.dealValue,
  }

  const pipelineValues = await pipelinesApi.updateDealValue(dealPayload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

// Clients pipeline
export const getNextClientDealsPage = async ({ state, commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)
  commit(mutationTypes.UPDATE_CLIENT_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_CLIENT_DEALS_COUNT, dealsCount)
}

export const createClientAndDeal = async ({ state, commit }, payload) => {
  const {
    clients: { clientsCount },
    clientDeals: { clientDealsCount },
  } = state
  const clientPayload = { new_client: payload.new_client }
  const { client } = await clientsApi.createClient(clientPayload)

  if (!payload.dealValue) {
    commit(mutationTypes.ADD_CLIENT, client)
    commit(mutationTypes.UPDATE_CLIENTS_COUNT, clientsCount + 1)

    return
  }

  const dealPayload = {
    deal_data: {
      subject: client.id,
      value: payload.dealValue,
    },
  }

  const { deal, pipeline_value, pipeline_projected_value } =
    await pipelinesApi.addDealWithValue(dealPayload)

  commit(mutationTypes.ADD_CLIENT_DEAL, deal)
  commit(mutationTypes.UPDATE_CLIENT_DEALS_COUNT, clientDealsCount + 1)

  const pipelineValues = {
    value: pipeline_value,
    projected_value: pipeline_projected_value,
  }

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const updateClientDealValue = async ({ commit }, dealUpdate) => {
  const pipelineValues = await pipelinesApi.updateDealValue(dealUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)
}

export const updateClientDealSubject = async ({ commit }, dealUpdate) => {
  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)

  const clientUpdate = {
    clientId: dealUpdate.subject,
    update: {
      client_update: {
        name: dealUpdate.subject_name,
        company: dealUpdate.subject_company,
      },
    },
  }

  await clientsApi.updateClient(clientUpdate)
}

export const openClientDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const searchClientDeals = async ({ commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)

  commit(mutationTypes.REPLACE_CLIENT_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_CLIENT_DEALS_COUNT, dealsCount)
}

export const closeClientDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_CLIENT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveClientsPipelineToProspects = async (
  { state, commit },
  payloads
) => {
  const { dealIds, subjectIds } = payloads
  const {
    clientDeals: { clientDealsCount },
  } = state
  const pipelineValues = await clientsApi.moveClientsToProspects({
    ids: subjectIds,
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_CLIENT_DEALS, dealIds)
  commit(mutationTypes.REMOVE_CLIENTS_FROM_LIST, subjectIds)
  commit(
    mutationTypes.UPDATE_CLIENT_DEALS_COUNT,
    clientDealsCount - dealIds.length
  )
}

export const triggerDeletedClientDeals = async ({}, dealsData) => {}

export const removeClientDeals = async (
  { state, commit, dispatch },
  payload
) => {
  const {
    clientDeals: { clientDealsList, clientDealsCount },
  } = state
  const deletedDeals = clientDealsList.filter((i) => payload.includes(i.id))
  const dealsData = {
    deals_ids: [],
    subject_ids: [],
  }

  deletedDeals.forEach((i) => {
    dealsData.deals_ids.push(i.id)
    dealsData.subject_ids.push(i.subject)
  })

  commit(mutationTypes.REMOVE_CLIENT_DEALS, payload)
  commit(
    mutationTypes.UPDATE_CLIENT_DEALS_COUNT,
    clientDealsCount - payload.length
  )

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  dispatch('triggerDeletedClientDeals', dealsData)
}

export const changeClientPipelineStatus = async (
  { commit, dispatch },
  payload
) => {
  const { clientId, isClientInPipeline } = payload

  if (!isClientInPipeline) {
    await dispatch('addDeals', { subject_ids: [clientId] })

    commit(mutationTypes.UPDATE_CLIENT, {
      id: clientId,
      is_in_pipeline: CustomerPipelineStatusCode.IN_PIPELINE,
    })

    return
  }

  const { deal: dealInResponse } = await pipelinesApi.getDealBySubject(clientId)
  const pipelineValues = await pipelinesApi.removeDeals({
    ids: [dealInResponse.id],
  })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  const undoRedoPayload = {
    deals_ids: [dealInResponse.id],
    subject_ids: [dealInResponse.subject],
  }

  commit(mutationTypes.UPDATE_CLIENT, {
    id: clientId,
    is_in_pipeline: CustomerPipelineStatusCode.NOT_IN_PIPELINE,
  })

  await dispatch('triggerDeletedClientDeals', undoRedoPayload)
}

export const clearClientDeals = async ({ commit }) => {
  commit(mutationTypes.CLEAR_CLIENT_DEALS)
  commit(mutationTypes.UPDATE_CLIENT_DEALS_COUNT, 0)
}

// Prospects pipeline
export const createProspectAndDeal = async ({ state, commit }, payload) => {
  const prospectPayload = { new_prospect: payload.new_prospect }
  const { prospect, prospects_count: prospectsCount } =
    await prospectsApi.createProspect(prospectPayload)
  const prospectStatus = prospect.status

  if (prospectStatus === ProspectStatus['Not in Pipeline']) {
    commit(mutationTypes.ADD_PROSPECT, prospect)
    commit(mutationTypes.UPDATE_PROSPECTS_COUNT, prospectsCount)

    return
  }

  const { deal: dealInResponse } = await pipelinesApi.getDealBySubject(
    prospect.id
  )

  const dealPayload = {
    id: dealInResponse.id,
    value: payload.dealValue,
  }

  const pipelineValues = await pipelinesApi.updateDealValue(dealPayload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)

  dealInResponse.value = payload.dealValue

  if (prospectStatus === ProspectStatus.Hot) {
    const {
      hotDeals: { hotDealsCount },
    } = state
    commit(mutationTypes.ADD_HOT_DEAL, dealInResponse)
    commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount + 1)
  }

  if (prospectStatus === ProspectStatus['In Progress']) {
    const {
      inProgressDeals: { inProgressDealsCount },
    } = state
    commit(mutationTypes.ADD_IN_PROGRESS_DEAL, dealInResponse)
    commit(
      mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT,
      inProgressDealsCount + 1
    )
  }

  if (prospectStatus === ProspectStatus.Target) {
    const {
      targetDeals: { targetDealsCount },
    } = state
    commit(mutationTypes.ADD_TARGET_DEAL, dealInResponse)
    commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount + 1)
  }
}

export const updateProspectDealSubject = async ({ commit }, dealUpdate) => {
  if (dealUpdate.deal_type === DealType.HotProspectDeal) {
    commit(mutationTypes.UPDATE_HOT_DEAL, dealUpdate)
  } else if (dealUpdate.deal_type === DealType.InProgressProspectDeal) {
    commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, dealUpdate)
  } else if (dealUpdate.deal_type === DealType.TargetProspectDeal) {
    commit(mutationTypes.UPDATE_TARGET_DEAL, dealUpdate)
  }

  const prospectUpdate = {
    prospectId: dealUpdate.subject,
    update: {
      prospect_update: {
        name: dealUpdate.subject_name,
        company: dealUpdate.subject_company,
        status: dealUpdate.deal_type,
      },
    },
  }

  await prospectsApi.updateProspect(prospectUpdate)
}

export const getNextHotDealsPage = async ({ commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)
  commit(mutationTypes.UPDATE_HOT_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, dealsCount)
}

export const searchHotPropspectDeals = async ({ commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)

  commit(mutationTypes.SET_HOT_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, dealsCount)
}

export const updateHotDealValue = async ({ commit }, payload) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_HOT_DEAL, payload)
}

export const openHotDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_HOT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const closeHotDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_HOT_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveHotDealToInProgress = async ({ state, commit }, payload) => {
  const {
    hotDeals: { hotDealsCount, hotDealsList },
    inProgressDeals: { inProgressDealsCount },
  } = state

  const deal = hotDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.InProgressProspectDeal

  commit(mutationTypes.REMOVE_HOT_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - 1)
  commit(mutationTypes.ADD_IN_PROGRESS_DEAL, deal)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveHotDealToTarget = async ({ state, commit }, payload) => {
  const {
    hotDeals: { hotDealsCount, hotDealsList },
    targetDeals: { targetDealsCount },
  } = state

  const deal = hotDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.TargetProspectDeal

  commit(mutationTypes.REMOVE_HOT_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - 1)
  commit(mutationTypes.ADD_TARGET_DEAL, deal)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const removeHotDeals = async ({ state, commit }, payload) => {
  const {
    hotDeals: { hotDealsCount },
  } = state
  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.REMOVE_HOT_DEALS, payload)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount - payload.length)
}

export const getNextInProgressDealsPage = async ({ commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, dealsCount)
}

export const updateInProgressDealValue = async ({ commit }, payload) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, payload)
}

export const openInProgressDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const closeInProgressDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_IN_PROGRESS_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const searchInProgressPropspectDeals = async ({ commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)

  commit(mutationTypes.SET_IN_PROGRESS_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, dealsCount)
}

export const moveInProgressDealToHot = async ({ state, commit }, payload) => {
  const {
    inProgressDeals: { inProgressDealsCount, inProgressDealsList },
    hotDeals: { hotDealsCount },
  } = state

  const deal = inProgressDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.HotProspectDeal

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount - 1)
  commit(mutationTypes.ADD_HOT_DEAL, deal)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveInProgressDealToTarget = async (
  { state, commit },
  payload
) => {
  const {
    inProgressDeals: { inProgressDealsCount, inProgressDealsList },
    targetDeals: { targetDealsCount },
  } = state

  const deal = inProgressDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.TargetProspectDeal

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount - 1)
  commit(mutationTypes.ADD_TARGET_DEAL, deal)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const removeInProgressDeals = async ({ state, commit }, payload) => {
  const {
    inProgressDeals: { inProgressDealsCount },
  } = state

  commit(mutationTypes.REMOVE_IN_PROGRESS_DEALS, payload)
  commit(
    mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT,
    inProgressDealsCount - payload.length
  )

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const getNextTargetDealsPage = async ({ state, commit }, payload) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)
  commit(mutationTypes.UPDATE_TARGET_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, dealsCount)
}

export const searchTargetPropspectDeals = async (
  { state, commit },
  payload
) => {
  const { deals_count: dealsCount, next_page: nextPage } =
    await pipelinesApi.getDeals(payload)

  commit(mutationTypes.SET_TARGET_DEALS_LIST, nextPage)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, dealsCount)
}

export const updateTargetDealValue = async ({ commit }, payload) => {
  const pipelineValues = await pipelinesApi.updateDealValue(payload)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
  commit(mutationTypes.UPDATE_TARGET_DEAL, payload)
}

export const openTargetDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: false }

  commit(mutationTypes.UPDATE_TARGET_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.openDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const closeTargetDeal = async ({ commit }, payload) => {
  const { id } = payload
  const dealUpdate = { id, is_closed: true }

  commit(mutationTypes.UPDATE_TARGET_DEAL, dealUpdate)

  const pipelineValues = await pipelinesApi.closeDeal(id)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveTargetDealToHot = async ({ state, commit }, payload) => {
  const {
    targetDeals: { targetDealsCount, targetDealsList },
    hotDeals: { hotDealsCount },
  } = state

  const deal = targetDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.HotProspectDeal

  commit(mutationTypes.REMOVE_TARGET_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount - 1)
  commit(mutationTypes.ADD_HOT_DEAL, deal)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, hotDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const moveTargetDealToInProgress = async (
  { state, commit },
  payload
) => {
  const {
    targetDeals: { targetDealsCount, targetDealsList },
    inProgressDeals: { inProgressDealsCount },
  } = state

  const deal = targetDealsList.find((i) => i.id === payload.dealId)
  deal.deal_type = DealType.InProgressProspectDeal

  commit(mutationTypes.REMOVE_TARGET_DEALS, [payload.dealId])
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, targetDealsCount - 1)
  commit(mutationTypes.ADD_IN_PROGRESS_DEAL, deal)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, inProgressDealsCount + 1)

  const prospectUpdate = {
    prospectId: payload.prospectId,
    update: {
      prospect_update: {
        name: payload.name,
        company: payload.company,
        status: payload.status,
      },
    },
  }

  const pipelineValues = await prospectsApi.updateProspect(prospectUpdate)

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const removeTargetDeals = async ({ state, commit }, payload) => {
  const {
    targetDeals: { targetDealsCount },
  } = state

  commit(mutationTypes.REMOVE_TARGET_DEALS, payload)
  commit(
    mutationTypes.UPDATE_TARGET_DEALS_COUNT,
    targetDealsCount - payload.length
  )

  const pipelineValues = await pipelinesApi.removeDeals({ ids: payload })

  commit(mutationTypes.UPDATE_PIPELINE_FIELDS, pipelineValues)
}

export const clearHotDeals = async ({ commit }) => {
  commit(mutationTypes.CLEAR_HOT_DEALS)
  commit(mutationTypes.UPDATE_HOT_DEALS_COUNT, 0)
}

export const clearInProgressDeals = async ({ commit }) => {
  commit(mutationTypes.CLEAR_IN_PROGRESS_DEALS)
  commit(mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT, 0)
}

export const clearTargetDeals = async ({ commit }) => {
  commit(mutationTypes.CLEAR_TARGET_DEALS)
  commit(mutationTypes.UPDATE_TARGET_DEALS_COUNT, 0)
}

export const clearAllProspectDeals = async ({ commit }) => {
  commit(mutationTypes.CLEAR_ALL_PROSPECT_DEALS)
}

// Completed Sales
export const getCompletedSalesPage = async ({ state, commit }, payload) => {
  const { next_page: nextPage, completed_deals_count: completedSalesCount } =
    await pipelinesApi.getCompletedDeals(payload)

  commit(mutationTypes.UPDATE_COMPLETED_SALES_LIST, nextPage)
  commit(mutationTypes.UPDATE_COMPLETED_SALES_COUNT, completedSalesCount)
}

export const clearCompletedSales = async ({ commit }) => {
  commit(mutationTypes.CLEAR_COMPLETED_SALES)
  commit(mutationTypes.UPDATE_COMPLETED_SALES_COUNT, 0)
}

// Subscriptions
export const getCardsList = async ({ state, commit }, payload) => {
  const cards = await subscriptionsApi.getCardList(payload)
  commit(mutationTypes.SET_CARD_LIST, cards)
}

export const searchSubscription = async ({ state, commit }, payload) => {
  const { billings: billingsList } = await subscriptionsApi.searchSubscription(
    payload
  )
  commit(mutationTypes.REPLACE_BILLINGS_LIST, billingsList)
}

export const getAdminBillingHistory = async ({ state, commit }, payload) => {
  const { lastId } = payload
  const {
    billingAdminHistory: { billingList },
  } = state
  const indexOfLastId = billingList.findIndex((user) => user.id === lastId)

  if (indexOfLastId + 1 === billingList.length) {
    const { billings: nextPage, next_page_number: nextPageNumber } =
      await subscriptionsApi.getBillingAdminHistory(payload)
    commit(mutationTypes.UPDATE_BILLINGS_LIST, nextPage)

    return nextPageNumber
  }
}

export const grantedSubscription = async ({ state, commit }, payload) => {
  await subscriptionsApi.grantedSubscription(payload)
  commit(mutationTypes.GRANT_SUBSCRIPTION, payload)
}

export const revokeSubscription = async ({ state, commit }, payload) => {
  await subscriptionsApi.revokeSubscription(payload)
  commit(mutationTypes.REVOKE_SUBSCRIPTION, payload)
}

export const addCard = async ({ commit }, payload) => {
  await subscriptionsApi.attachCard(payload.id)
  commit(mutationTypes.ADD_CARD, payload)
}

export const setCardAsDefault = async ({ dispatch }, payload) => {
  await subscriptionsApi.setCardAsDefault(payload)

  const userUpdate = { subscription: { default_payment_method: payload } }
  await dispatch('updateUserLocally', userUpdate)
}

export const deleteCard = async ({ commit }, payload) => {
  await subscriptionsApi.removeCard(payload)
  commit(mutationTypes.DELETE_CARD, payload)
}

// Reports
export const getMonthReportHtml = async ({}, pipelineId) => {
  const html = await reportsApi.getMonthReportHtml(pipelineId)
  const opt = {
    margin: 0,
    html2canvas: { width: 1440, height: 1022 },
    jsPDF: { unit: 'px', orientation: 'l', format: [1440, 1022] },
  }

  html2pdf().set(opt).from(html).toPdf().save('report.pdf')
}
export const generateMemberReport = async ({}, { pipelineId, userId }) => {
  const html = await reportsApi.generateMemberReportHtml(pipelineId, userId)
  const opt = {
    margin: 0,
    html2canvas: { width: 1440, height: 1022 },
    jsPDF: { unit: 'px', orientation: 'l', format: [1440, 1022] },
  }

  html2pdf().set(opt).from(html).toPdf().save('report.pdf')
}
